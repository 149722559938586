import axios from 'axios';

const token_key = localStorage.getItem('token');
let setHeaders;
if (token_key && token_key != null) {
    setHeaders = {
        'Cache-Control': 'no-cache',
        Authorization: `Bearer ${token_key}`,
    };
} else {
    setHeaders = { 'Cache-Control': 'no-cache' };
}
export default axios.create({
    baseURL: process.env.VUE_APP_API_URL || 'http://api.platcorpgroup.com',
    headers: setHeaders,
});

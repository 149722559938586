import { userService } from '@/services/user.service';
import router from '../../router/index';

export const state = {
    user: JSON.parse(localStorage.getItem('user')),
    token: localStorage.getItem('token'),
    status: {},
};
export const actions = {
    // Logs in the user.
    // eslint-disable-next-line no-unused-vars
    async login({ dispatch, commit }, userResult) {
        commit('loginRequest', { email: userResult.email });

        try {
            // const { offline } = userResult;
            // if (offline) {
            //     await commit('loginSuccess', userResult, 'offline');
            //     setTimeout(() => {
            //         if (getters['user/isLoanOfficer'] == true) {
            //             router.go('/agents/landingpage').catch(() => {});
            //         } else {
            //             router.push('/admin/client-list').catch(() => {});
            //         }
            //     }, 50);
            // }

            const { token, refreshToken } = userResult;

            await userService.authenticateApi({
                token: token,
                refreshToken: refreshToken,
            });
            delete userResult.token;
            delete userResult.refreshToken;
            await commit('loginSuccess', userResult, token);

            // this fetch tenants must be done by username
            //await dispatch('tenant/fetchTenants', Id, { root: true });

            //
            //console.log('LOGGED IN', userResult)

            if (userResult.isOAuth) {
                window.location.href = `${userResult.oauth.redirectUri}?success=true&token=${token}`;
            } else {
                setTimeout(() => {
                    if (getters['user/isLoanOfficer'] == true) {
                        // router.go('/agents/landingpage').catch(() => {});
                    } else {
                        //handle domain redirect here
                        //if (window.location.indexOf('platcorpgroup.com') && user.te)
                        router.go('/admin/client-list');
                    }
                }, 900);
            }
        } catch (error) {
            commit('loginFailure', error);
            dispatch('notification/error', error, { root: true });
        }
    },
    // Logout the user
    logout({ commit }) {
        userService.logout();
        commit('logout');
    },
    // register the user
    registeruser({ dispatch, commit }, user) {
        commit('registerRequest', user);
        userService.register(user).then(
            user => {
                commit('registerSuccess', user);
                dispatch('notification/success', 'Registration successful', {
                    root: true,
                });
                router.push('/login').catch(() => {});
            },
            error => {
                commit('registerFailure', error);
                dispatch('notification/error', error, { root: true });
            },
        );
    },
};

export const getters = {
    // Whether the user is currently logged in.
    loggedIn() {
        const token = localStorage.getItem('token');
        if (token == null) {
            return false;
        }
        // if (helper.isTokenExpired(token)) {
        //     localStorage.removeItem('token');
        // }

        return token != null;
    },
    getCurrentUser() {
        return state.user || false;
    },
};

export const mutations = {
    loginRequest(state, user) {
        state.status = { loggingIn: true };
        state.user = user;
    },
    async loginSuccess(state, user, token) {
        state.user = user;
        state.token = token;
        localStorage.setItem('user', JSON.stringify(user));
        return;
    },
    loginFailure(state) {
        state.user = null;
    },
    logout(state) {
        state.user = null;
    },
    registerRequest(state) {
        state.status = { registering: true };
    },
    registerSuccess(state) {
        state.status = {};
    },
    registerFailure(state) {
        state.status = {};
    },
};

import store from '@/state/store';

export default [

    {
        path: '/admin/login',
        name: 'login',
        meta: {
            layout: 'main',
            requireAuth: false,
            beforeResolve(routeTo, routeFrom, next) {
                // If the user is already logged in
                if (store.getters['auth/loggedIn']) {
                    //Redirect to the client-list page
                    next('/admin/client-list');
                } else {
                    // Continue to the login page
                    next();
                }
            },
        },
        component: () => import('../pages/admin/login.vue'),
    },
    {
        path: '/admin/logout',
        name: 'logout',
        meta: {
            layout: 'main',
            requireAuth: false,
        },
        component: () => import('../pages/admin/logout.vue'),
    },
    {
        path: '/admin/client-list',
        name: 'client-list',
        meta: {
            requireAuth: true,
            layout: 'main'
        },
        component: () => import('../pages/admin/client-list.vue'),
    },
    {
        path: '/admin/update/:clientId',
        name: 'client-details-update',
        meta: {
            requireAuth: true,
            layout: 'main'
        },
        component: () => import('../pages/admin/clients/update.vue'),
    },
    {
        path: '/signup/thank-you',
        name: 'thank-you-intent',
        meta: {
            layout: 'main',
            requireAuth: false
        },
        component: () => import('../pages/thank-you/thank-you-intent.vue'),
    },
    {
        path: '/confirmed/thank-you',
        name: 'thank-you-consent',
        meta: {
            layout: 'main',
            requireAuth: false
        },
        component: () => import('../pages/thank-you/thank-you-consent.vue'),
    },
    {
        path: '/sign-up/:referredBy?', //optional refferral Code of referree
        name: 'signup',
        meta: {
            layout: 'main',
            requireAuth: false
        },
        component: () => import('../pages/signup'),
    },
    // {
    //     path: '/mytvsbike/:referredBy?', //optional refferral Code of referree
    //     name: 'mytvsbike',
    //     meta: {
    //         layout : 'main',
    //         requireAuth: false
    //     },
    //     component: () => import('../pages/signup/mytvsbike.vue'),
    // },
    {
        path: '/myherobike/:referredBy?', //optional refferral Code of referree
        name: 'myherobike',
        meta: {
            layout: 'main',
            requireAuth: false
        },
        component: () => import('../pages/signup/myherobike.vue'),
    },
    {
        path: '/my-tvs-125/:referredBy?', //optional refferral Code of referree
        name: 'my-tvs-125',
        meta: {
            layout: 'main',
            requireAuth: false
        },
        component: () => import('../pages/signup/mytvs125.vue'),
    },
    {
        path: '/confirm/:clientId',
        name: 'consent',
        meta: {
            layout: 'main',
            requireAuth: false
        },
        component: () => import('../pages/consent'),
    },
    {
        path: '/confirm-refinance/:clientId',
        name: 'consent-refinance',
        meta: {
            layout: 'main',
            requireAuth: false
        },
        component: () => import('../pages/consent-refinance'),
    },
    {
        path: '/:pathMatch(.*)*',
        name: 'not-found',
        meta: {
            layout: 'main',
            requireAuth: false
        },
        component: () => import('../pages/404'),
    },
];

import { createApp, h } from 'vue';

import "bootstrap/dist/css/bootstrap.min.css"
import "bootstrap"
import '@/assets/sass/styles.scss';
import router from './router'
import VueSignaturePad from 'vue-signature-pad';
import App from './App.vue'

import BootstrapVue from 'bootstrap-vue-3';
import store from '@/state/store';
import { version } from '../package.json';
import axios from './helpers/api';
import DropZone from 'dropzone-vue';

import Datepicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css';

// As a plugin
import VueGoodTablePlugin from 'vue-good-table-next';
import vfmPlugin from 'vue-final-modal';

// import the styles
import 'vue-good-table-next/dist/vue-good-table-next.css';
import '@/assets/scss/app.scss';

import 'dropzone-vue/dist/dropzone-vue.common.css';

const app = createApp({
    render: () => h(App),
});

app.use(router);
app.use(VueSignaturePad);

app.use(BootstrapVue);
app.use(store);
app.use(VueGoodTablePlugin);
app.use(vfmPlugin);
app.component(DropZone);
app.component('Datepicker', Datepicker);

axios.interceptors.request.use(
    config => {
        config.headers['x-ui-version'] = `${version}`;
        return config;
    },
    error => {
        return Promise.reject(error);
    }
);

axios.interceptors.response.use(
    response => {
        return response;
    },
    error => {
        const status = error.response.status || 500;
        switch (status) {
            case 401: {
                localStorage.removeItem('user');
                localStorage.removeItem('token');
                localStorage.removeItem('tenants');
                localStorage.removeItem('refreshToken');
                delete axios.defaults.headers.common.Authorization;
                if (window.location.href.indexOf('login') == -1) {
                    return router.go('/login');
                }
                break;
            }
            default: {
                return Promise.reject(error);
            }
        }
    },
);

router.isReady().then(() => {
    app.mount('#app');
});

import { createRouter, createWebHistory } from 'vue-router';

import routes from './routes';
import store from '@/state/store';

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes,
    scrollBehavior(to, from, savedPosition) {
        if (savedPosition) {
            return savedPosition;
        }
        return {
            x: 0,
            y: 0,
        };
    },
});

// Before each route evaluates...
router.beforeEach(async (routeTo, routeFrom, next) => {
    // Check if auth is required on this route
    const authRequired = routeTo.matched.some(route => route.meta.requireAuth);
    // If auth isn't required for the route, just continue.
    if (!authRequired) return next();

    await tokenVerification();
    const isLoggedIn = await store.getters['auth/loggedIn'];
    if (isLoggedIn) {
        // check permissions
        const routePermissions = routeTo.meta.allowPermissions || ['All'];
        const user = JSON.parse(localStorage.getItem('user'));
        user.permissions?.push.apply(
            user.permissions,
            user.rolepermissions || [],
        );
        const userPermissions = user.permissions;
        // validate only if route has specific permissions
        if (routePermissions.length !== 1 && routePermissions[0] !== 'All') {
            const userHasPermissions = userPermissions.some(userPermission => {
                return routePermissions.include(userPermission.name);
            });
            if (!userHasPermissions) {
                redirectToNotAuthorized();
            }
        }

        // TODO: Validate Token
        return next();
    }
    redirectToLogin();

    function redirectToLogin() {
        // Pass the original route to the login component
        return next({
            name: 'login',
            query: {
                redirectFrom: routeTo.fullPath,
            },
        });
    }

    function redirectToNotAuthorized() {
        return next({
            name: 'not-authorized',
            query: {
                redirectFrom: routeTo.fullPath,
            }
        });
    }
});

const tokenVerification = async () => {
    const tokenKey = localStorage.getItem('token');
    if (!tokenKey) {
        router.push('/admin/login');
    }
};


router.beforeResolve(async (routeTo, routeFrom, next) => {
    // Create a `beforeResolve` hook, which fires whenever
    // `beforeRouteEnter` and `beforeRouteUpdate` would. This
    // allows us to ensure data is fetched even when params change,
    // but the resolved route does not. We put it in `meta` to
    // indicate that it's a hook we created, rather than part of
    // Vue Router (yet?).
    try {
        // For each matched route...
        for (const route of routeTo.matched) {
            await new Promise((resolve, reject) => {
                // If a `beforeResolve` hook is defined, call it with
                // the same arguments as the `beforeEnter` hook.
                if (route.meta && route.meta.beforeResolve) {
                    route.meta.beforeResolve(routeTo, routeFrom, (...args) => {
                        // If the user chose to redirect...
                        if (args.length) {
                            // If redirecting to the same route we're coming from...
                            // Complete the redirect.
                            next(...args);
                            reject(new Error('Redirected'));
                        } else {
                            resolve();
                        }
                    });
                } else {
                    // Otherwise, continue resolving the route.
                    resolve();
                }
            });
        }
        // If a `beforeResolve` hook chose to redirect, just return.
    } catch (error) {
        return;
    }

    // If we reach this point, continue resolving the route.
    next();
});

export default router;
